* {
    box-sizing: border-box;
}

body {
    font-family: "Karla", sans-serif;
    margin: 0;
    font-size: 10pt;
}

main {
    padding: 18px;
    margin-top: 65px;
}

button {
    text-align: center;
    border: none;
    padding: 0.5em 0em;
    display: inline-block;
    font-size: 1.1rem;
    border-radius: 10px;
}

input {
    margin: 0 1em;
    float: left;
    align-self: center
}

.radio--text {
    margin-right: 1em;
    display: block;
}

label {
    display: flex;
}

.radio {
    margin: 1em;
    padding: 1em 0em;
    border-radius: 10px;
    background: linear-gradient(60deg, #74c215 1%, rgb(183, 225, 98) 100%);
}

.header {
    display: flex;
    align-items: center;
    height: 65px;
    background: linear-gradient(90deg, #224f80 1.18%, #326fb2 100%);
    color: white;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.header--title {
    font-size: 1.5rem;
    margin-right: auto;
}

.header--project {
    font-size: 1rem;
    font-weight: 500;
}

.licenses {
    background: linear-gradient(90deg, #f8be52 1%, #f8d452 100%);
    border-radius: 5px;
    padding: 1em 2em;
    margin: 0;
    align-items: center;
}

.licenses > h4 {
    padding: 0;
    margin: 0.5em 0em;
}

.licenses--selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.licenses--selection > label {
    text-align: center;
    padding: 0 1em;
}

.licenses--button, .button-false-questions {
    width: 100%;
    align-self: center;
    background-color: #ef8316;
    margin: 1em 0 0 0;
    margin-right: auto;
}

.licenses--button {
    background-color: #ef8316;
}

.licenses--button:hover {
    background-color: #f19130;
}

.button-false-questions {
    background-color: #aeccee;
}

.button-false-questions:hover {
    background-color: #bcd5ef;
}

.questionbox {
    align-items: center;
    padding: 1em;
    border-radius: 5px;
    background: linear-gradient(90deg, #e8e8e8 1%, rgb(243, 241, 241) 100%);
}


.mcq--question {
    position: relative;
    margin: 1em;
}

.mcq--question > h2 {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0.3em 0em;
    line-height: 1.3em;
}

.mcq--buttons {
    display: flex;
    align-items: flex-end;
    margin: 1.5em 1em 0.5em 1em;
    justify-content: space-between;
}

.mcq--buttons > button {
    width: 40%;
    background-color: #326fb2;
    margin: 0;
    color: white;
}

.button-right {
    margin-left: auto;
}

.mcq--buttons > button:hover {
    background-color: #4690e1;
}

.mcq--buttons > button:disabled {
    background-color: #326fb2;
}

.progressbar {
    height: 0.8em;
    display: flex;
    align-content: center;
    align-items: center;
    margin: 1em -1em -1em -1em;
    border-top: 0.5px solid white;
}

.progressbar--progress {
    height: 100%;
    background: linear-gradient(90deg, #224f80 1.18%, rgba(118, 165, 215, 0.49) 100%);
    border-radius: 0px 0px 5px 5px;
    textAlign: 'right';
    margin-right: auto;
}

.progressbar > span {
    float: right;
    font-size: 0.7rem;
    margin-right: 0.5em;
    align-self: center;
    color: rgba(197,194,194,0.85);
}


